import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsXrayCollapsed } from "../../../../../redux/slices/appSlice";
import Icon from "../../../../../utils/components/Icon";
import usePostMessage from "../../../../../utils/controllers/usePostMessage";

function TopBar({ currentEngData, totalEngagements, handlePopEngagementData }) {
  const dispatch = useDispatch();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );
  const headingText = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.title
  );
  const poweredBy_TEXT = useSelector(
    (state) => state.language.data.poweredBy_TEXT
  );
  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const { postMessage } = usePostMessage();

  const onCollapseHandler = (data) => {
    dispatch(setIsXrayCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  const closeIcon = (style) => {
    if (isXrayCollapsed) {
      return (
        <Icon
          name="ri-add-line"
          onClick={() => {
            onCollapseHandler(false);
          }}
          style={{
            cursor: "pointer",
            ...style,
            zIndex: 2,
          }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-arrow-up-s-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            cursor: "pointer",
            zIndex: 2,
          }}
        />
      );
    }
  };

  return (
    <div
      style={{
        cursor: isXrayCollapsed && "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#151515",
        color: "white",
        padding: "var(--mpr-2)",
        height: "56px",
        minHeight: "56px",
      }}
      className="xrayThreeTopBar"
      onClick={() => {
        if (isXrayCollapsed) {
          onCollapseHandler(false);
          sendCollapseEvent();
        }
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "var(--mpr-mini)",
          zIndex: 2,
        }}
      >
        <div className="engTopbarLogoDiv">
          {!logo && <p>{poweredBy_TEXT}</p>}
          <img
            onClick={() => {
              if (!logo) {
                window.open("https://www.bridged.media/", "_blank");
              }
            }}
            alt="logo"
            src={logo || bridgedLogo}
          />
        </div>

        <img
          style={{ height: "22px" }}
          src="/ai_stars.svg"
          alt="Stars"
          className="starAnimation xrayThreeTopBarStarImage"
        />

        <span className="firstLine">
          {isXrayCollapsed
            ? headingText || currentEngData?.questionText
            : currentEngData?.questionText}
        </span>
      </div>

      {closeIcon()}

      <div className="aroraAnimation aroraAnimation1" />
      <div className="aroraAnimation aroraAnimation2" />
      <div className="aroraAnimation aroraAnimation3" />
    </div>
  );
}

export default TopBar;
