import TopBar from "../components/three/TopBar";
import useXrayDefault from "../controllers/useXrayDefault";
import { useSelector } from "react-redux";
import CurrentXrayWorkflow from "./CurrentXrayWorkflow";
import StarRatings from "react-star-ratings";
import useFeedbackHandler from "../../reusable/components/controllers/useFeedbackHandler";
import KAFooter from "../../knowledgeAgent/components/KAFooter";
function Three() {
  const feedbackConfig = useSelector(
    (state) => state.flipcard.data.config?.feedbackConfig
  );

  const disclaimer = useSelector(
    (state) => state.flipcard.data.config?.disclaimer
  );
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const { engagementData, handlePopEngagementData } = useXrayDefault();
  const { rating, changeRating } = useFeedbackHandler();

  return (
    <div className="xray">
      <TopBar
        currentEngData={engagementData[engagementData?.length - 1]}
        totalEngagements={engagementData?.length}
        handlePopEngagementData={handlePopEngagementData}
      />

      {!isXrayCollapsed && (
        <div
          style={{
            backgroundColor: "#F0F0F0",
            width: "100%",
          }}
        >
          <div className="xrayThreeBody">
            <CurrentXrayWorkflow
              currentEngData={engagementData[engagementData?.length - 1]}
              isPadding={true}
              isTwoTheme={true}
            />
          </div>

          <div className="xrayThreeFooter">
            {disclaimer ? (
              <div>
                <h5 style={{ fontWeight: 400 }}>{disclaimer}</h5>
              </div>
            ) : (
              <div />
            )}
            {feedbackConfig?.isActive ? (
              <div>
                <StarRatings
                  rating={rating}
                  changeRating={changeRating}
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  starSpacing="1px"
                  starHoverColor="#A6A6A6"
                  starRatedColor="#A6A6A6"
                  starEmptyColor="#D7D7D7"
                />
              </div>
            ) : (
              <div />
            )}

            <div>
              <KAFooter />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Three;
