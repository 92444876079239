import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../../../utils/components/Icon";
import { useSelector } from "react-redux";
import getTextColor from "../../../../../utils/controllers/getTextColor";

// [
//   {
//     title: "John Doe",
//     price: "£30/hour",
//     description:
//       "Experienced Math tutor with over 10 years of teaching in secondary education.",
//     rating: "4.8/5",
//     profileURL: "https://tutorful.co.uk/tutors/pdkodqnj",
//     pfpURL:
//       "https://cdn.tutorful.co.uk/profile-pictures/pdkodqnj-j3mkbgyl@340x340.jpg",
//     metadata: {
//       tagline: "Expert in Algebra and Calculus",
//       qualifiedTeacherStatus: true,
//       freeVideoCall: true,
//     },
//   },
//   {
//     title: "Jane Smith",
//     price: "£25/hour",
//     description:
//       "Qualified Science teacher specializing in Chemistry and Physics.",
//     rating: "4.9/5",
//     profileURL: "https://tutorful.co.uk/tutors/7eydgbal",
//     pfpURL:
//       "https://cdn.tutorful.co.uk/profile-pictures/7eydgbal-4eeqgrdg@340x340.jpg",
//     metadata: {
//       tagline: "Science Tutor with a Passion for Experimentation",
//       qualifiedTeacherStatus: true,
//       freeVideoCall: false,
//     },
//   },
//   {
//     title: "John Doe",
//     price: "£30/hour",
//     description:
//       "Experienced Math tutor with over 10 years of teaching in secondary education.",
//     rating: "4.8/5",
//     profileURL: "https://tutorful.co.uk/tutors/pdkodqnj",
//     pfpURL:
//       "https://cdn.tutorful.co.uk/profile-pictures/pdkodqnj-j3mkbgyl@340x340.jpg",
//     metadata: {
//       tagline: "Expert in Algebra and Calculus",
//       qualifiedTeacherStatus: true,
//       freeVideoCall: true,
//     },
//   },
//   {
//     title: "Jane Smith",
//     price: "£25/hour",
//     description:
//       "Qualified Science teacher specializing in Chemistry and Physics.",
//     rating: "4.9/5",
//     profileURL: "https://tutorful.co.uk/tutors/7eydgbal",
//     pfpURL:
//       "https://cdn.tutorful.co.uk/profile-pictures/7eydgbal-4eeqgrdg@340x340.jpg",
//     metadata: {
//       tagline: "Science Tutor with a Passion for Experimentation",
//       qualifiedTeacherStatus: true,
//       freeVideoCall: false,
//     },
//   },
//   {
//     title: "John Doe",
//     price: "£30/hour",
//     description:
//       "Experienced Math tutor with over 10 years of teaching in secondary education.",
//     rating: "4.8/5",
//     profileURL: "https://tutorful.co.uk/tutors/pdkodqnj",
//     pfpURL:
//       "https://cdn.tutorful.co.uk/profile-pictures/pdkodqnj-j3mkbgyl@340x340.jpg",
//     metadata: {
//       tagline: "Expert in Algebra and Calculus",
//       qualifiedTeacherStatus: true,
//       freeVideoCall: true,
//     },
//   },
//   {
//     title: "Jane Smith",
//     price: "£25/hour",
//     description:
//       "Qualified Science teacher specializing in Chemistry and Physics.",
//     rating: "4.9/5",
//     profileURL: "https://tutorful.co.uk/tutors/7eydgbal",
//     pfpURL:
//       "https://cdn.tutorful.co.uk/profile-pictures/7eydgbal-4eeqgrdg@340x340.jpg",
//     metadata: {
//       tagline: "Science Tutor with a Passion for Experimentation",
//       qualifiedTeacherStatus: true,
//       freeVideoCall: false,
//     },
//   },
// ]

function KATutors({ data }) {
  const primaryColor = useSelector(
    (state) => state.flipcard.data.flipCard.theme.primaryColor
  );

  const textColor = getTextColor(primaryColor) || "white";
  const sliderRef = useRef(null);

  const [isScrollable, setIsScrollable] = useState(false);

  const checkScrollable = () => {
    const slider = sliderRef.current;
    setIsScrollable(slider && slider.scrollWidth > slider.clientWidth);
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [data]);

  const scrollNext = () => {
    sliderRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  const scrollPrev = () => {
    sliderRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  if (data?.length > 0) {
    return (
      <div className="kaTutors">
        <h4 className="kaTutorsHeading">Tutors:</h4>
        <div className="kaSliderControls">
          {isScrollable && (
            <button className="prevButton" onClick={scrollPrev}>
              <Icon name="ri-arrow-left-s-line" />
            </button>
          )}
          <div className="kaAllTutorialCards customScrollBar" ref={sliderRef}>
            {data?.map((tutor, index) => (
              <div key={index} className="kaTutorialCard">
                <img
                  src={tutor?.pfpURL}
                  className="kaTutorialCardProfilePicture"
                  alt="Profile"
                />
                <div className="kaTutorialCardBody">
                  <h3>
                    {tutor?.title}

                    <img
                      src="/icons/verify.png"
                      className="kaTutorialCardVerifyBadge"
                      alt="Success"
                    />
                  </h3>
                  <p>{tutor?.description}</p>
                  <h4>
                    Price: <span>{tutor?.price}</span>
                  </h4>
                  <h4>
                    Ratings: <span>{tutor?.rating}</span>
                  </h4>
                  <h4>
                    Free Video Call:{" "}
                    <span>{tutor?.metadata?.freeVideoCall ? "YES" : "NO"}</span>
                  </h4>
                  <h4>
                    Hrs taught: <span>{tutor?.metadata?.hoursTaught}+</span>
                  </h4>

                  <h4
                    style={{
                      height: "20px",
                      opacity: tutor?.metadata?.qualifiedTeacherStatus ? 1 : 0,
                    }}
                  >
                    Qualified teacher:{" "}
                    <span>
                      {tutor?.metadata?.qualifiedTeacherStatus ? "YES" : ""}
                    </span>
                  </h4>
                  <a
                    target="_blank"
                    href={tutor?.profileURL}
                    rel="noreferrer"
                    className="button"
                    style={{
                      color: textColor,
                    }}
                  >
                    Know more
                    <Icon
                      name="ri-external-link-line"
                      style={{ fontSize: "14px", marginLeft: "var(--mpr-3)" }}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
          {isScrollable && (
            <button className="nextButton" onClick={scrollNext}>
              <Icon name="ri-arrow-right-s-line" />
            </button>
          )}
        </div>
      </div>
    );
  }

  return <></>;
}

export default KATutors;
