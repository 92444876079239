import React from "react";
import "../styles/singlePageOne.css";
import useKnowledgeAgentController from "../controllers/useKnowledgeAgentController";
import { useSelector } from "react-redux";
import { getColorOpacityPercentage } from "../../../../utils/controllers/colors";
import TopBar from "../components/singlePageOne/TopBar";
import FAQ from "../components/singlePageOne/FAQ";
import Body from "../components/singlePageOne/Body";

function SinglePageOne() {
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    setInputQuery,
    isLoading,
    handeLinkClick,
    handRatingTake,
  } = useKnowledgeAgentController();

  const primaryColor = useSelector(
    (state) => state.flipcard.data.flipCard.theme.primaryColor
  );

  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);
  return (
    <div className="singlePageOne">
      <TopBar
        bgColorOpacity={bgColorOpacity}
        handleGenerateAnswer={handleGenerateAnswer}
        isLoading={isLoading}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        responseData={responseData}
      />

      {responseData?.length === 0 ? (
        <FAQ
          bgColorOpacity={bgColorOpacity}
          handleGenerateAnswer={handleGenerateAnswer}
        />
      ) : (
        <Body
          bgColorOpacity={bgColorOpacity}
          responseData={responseData}
          handRatingTake={handRatingTake}
          handeLinkClick={handeLinkClick}
          inputQuery={inputQuery}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default SinglePageOne;
